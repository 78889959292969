import Modal from '@/components/Modals/Modal/Modal'

import styles from './PartnerInfo.module.scss'

function PartnerInfo({ isOpen, onClose, partner }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.modalInner}>
        <p>{partner?.reklama?.name}</p>
        <p>{partner?.reklama?.inn}</p>
        <p>{partner?.reklama?.gov_id}</p>
      </div>
    </Modal>
  )
}

export default PartnerInfo
